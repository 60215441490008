@font-face {
  font-family: 'SF Pro';
  font-weight: 100;
  src: url('./SF-Pro-Display-Thin.otf');
}
@font-face {
  font-family: 'SF Pro';
  font-weight: 300;
  src: url('./SF-Pro-Display-Light.otf');
}
@font-face {
  font-family: 'SF Pro';
  font-weight: 400;
  src: url('./SF-Pro-Display-Regular.otf');
}
@font-face {
  font-family: 'SF Pro';
  font-weight: 500;
  src: url('./SF-Pro-Display-Medium.otf');
}
@font-face {
  font-family: 'SF Pro';
  font-weight: 600;
  src: url('./SF-Pro-Display-Semibold.otf');
}
@font-face {
  font-family: 'SF Pro';
  font-weight: 700;
  src: url('./SF-Pro-Display-Bold.otf');
}
@font-face {
  font-family: 'SF Pro';
  font-weight: 800;
  src: url('./SF-Pro-Display-Heavy.otf');
}
@font-face {
  font-family: 'SF Pro';
  font-weight: 900;
  src: url('./SF-Pro-Display-Black.otf');
} ;
